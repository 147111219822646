import React, { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { apiGet, apiPost } from "../Utils/apiFetch";
import { pick, isEmpty } from "lodash";
import apiPath from "../Utils/apiPath";
import { useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { io } from "socket.io-client";
import { toast } from "react-toastify";
import moment from "moment";
const AuthContext = createContext();
export default AuthContext;
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [message, setMessage] = useState({
    status: false,
    message: "",
    errorType: "",
  });
  const [matchLimit, setMatchLimit] = useState([]);
  const [blockLimit, setBlockLimit] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [parly, setParly] = useState(false);
  const [parlyBet, setParlyBet] = useState([]);
  const [showParlyBet, setShowParlyBet] = useState(false);
  const [casinoUrl, setCasinoUrl] = useState("");
  const [sokcetId, setSocketId] = useState({});
  const [scoreDetail, setScoreDetail] = useState("");
  const [active, setActive] = useState("sports");
  const [searchTab, setSearchTab] = useState(false);
  const [userCoins, setUserCoins] = useState({});
  let [user, setUser] = useState({});
  const [announcement, setAnnouncement] = useState(false);
  const [showWidget, setShowWidget] = useState(true);
  const [activeWidget, setActiveWidget] = useState("");
  const [scrollHeight, setScrollHeight] = useState(75);
  const [footerLink, setFooterLink] = useState({
    status: false,
    type: "",
  });
  const [showDate, setShowDate] = useState({
    status: false,
    type: "",
  });
  const [plDate, setPlDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [messagelist, setMessageList] = useState([]);
  const [refreshAmountLoader, setRefreshAmountLoader] = useState(false);
  const [plDateType, setPlDateType] = useState("");
  const [loginError, setLoginError] = useState("");
  const [keyTime, setKeyTime] = useState("in_play");
  const [gameTab, setGameTab] = useState("cricket");
  const [profileData, setProfileData] = useState({});
  const [defaultStakeLimit, setDefaultStakeLimit] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showPosition, setShowPosition] = useState({
    status: false,
    object: {},
  });
  const [checkRefresh, setCheckRefresh] = useState(true);
  const location = useLocation();
  const [socketObj, setSocketObj] = useState(null);
  useEffect(() => {
    if (localStorage.getItem("parly") == "true") {
      setParly(true);
    } else {
      setParly(false);
    }
  }, []);

  const updateParly = (value) => {
    localStorage.setItem("parly", value);
    setParly(value);
  };
  useEffect(() => {
    if (
      location?.pathname?.split("/")[1] !== "match-details" &&
      !isEmpty(sokcetId)
    ) {
      sokcetId.disconnect();
      setSocketId({});
    }
  }, [location]);
  useEffect(() => {
    if (secureLocalStorage.getItem("parlyBet")?.length > 0) {
      setParlyBet(secureLocalStorage.getItem("parlyBet"));
    }
  }, []);

  const [invalidCount, setInvalidCount] = useState(0);
  const [invalidCountTimer, setInvalidCountTimer] = useState({
    status: false,
  });
  const [call, setCall] = useState(false);
  useEffect(() => {
    if (
      !isEmpty(localStorage.getItem("invalidCount")) &&
      localStorage.getItem("invalidCount") > 0
    ) {
      setInvalidCount(Number(localStorage.getItem("invalidCount")));
    }
    let obj = !isEmpty(localStorage?.getItem("invalidCountTime"))
      ? JSON.parse(localStorage?.getItem("invalidCountTime"))
      : {};
    if (!isEmpty(obj) && obj?.status) {
      setInvalidCountTimer(obj);
    }
  }, []);

  const handelTimeCheck = (obj) => {
    if (
      moment(new Date()).isAfter(
        moment(obj?.time).add(Number(obj?.length), "minutes")
      )
    ) {
      setInvalidCountTimer({
        ...invalidCountTimer,
        status: false,
        isCompleted: true,
      });
      localStorage.setItem(
        "invalidCountTime",
        JSON.stringify({
          ...invalidCountTimer,
          status: false,
          isCompleted: true,
        })
      );
      return {
        ...invalidCountTimer,
        status: false,
        isCompleted: true,
      };
    } else {
      return invalidCountTimer;
    }
  };

  const handelTimerFunc = () => {
    if (invalidCount > 0) {
      let count = invalidCount + 1;
      let obj = handelTimeCheck(invalidCountTimer);
      if (count === 3) {
        if (!obj?.status) {
          if (obj?.isCompleted && obj?.next == 6) {
            setInvalidCount(count);
            localStorage.setItem("invalidCount", count);
          } else {
            setInvalidCountTimer({
              status: true,
              time: new Date(),
              length: 15,
              next: 6,
            });
            localStorage.setItem(
              "invalidCountTime",
              JSON.stringify({
                status: true,
                time: new Date(),
                length: 15,
                next: 6,
              })
            );
          }
        }
        toast.error("Invalid Password your account is blocked for 15 min");
      } else if (count == 6) {
        if (!obj?.status) {
          if (obj?.isCompleted && obj?.next == 6) {
            setInvalidCount(count);
            localStorage.setItem("invalidCount", count);
          } else {
            setInvalidCountTimer({
              status: true,
              time: new Date(),
              length: 60,
              next: 12,
            });
            localStorage.setItem(
              "invalidCountTime",
              JSON.stringify({
                status: true,
                time: new Date(),
                length: 60,
                next: 12,
              })
            );
          }
        }
        toast.error("Invalid Password your account is blocked for 1 hr");
      } else if (count == 9) {
        if (!obj?.status) {
          if (obj?.isCompleted && obj?.next == 12) {
            setInvalidCount(count);
            localStorage.setItem("invalidCount", count);
          } else {
            setInvalidCountTimer({
              status: true,
              time: new Date(),
              length: 1440,
              next: 24,
            });
            localStorage.setItem(
              "invalidCountTime",
              JSON.stringify({
                status: true,
                time: new Date(),
                length: 1440,
                next: 24,
              })
            );
          }
        }
        toast.error("Invalid Password your account is blocked for 24 hr");
      } else {
        if (!obj?.status) {
          setInvalidCount(count);
          localStorage.setItem("invalidCount", count);
          toast.error("Invalid password.");
        }
      }
    } else {
      setInvalidCount(1);
      localStorage.setItem("invalidCount", 1);
      toast.error("Invalid password.");
    }
    setCall(false);
  };

  useEffect(() => {
    if (call) {
      handelTimerFunc();
    }
  }, [call]);

  const handleShowSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  let logoutUser = () => {
    setUser(null);
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    secureLocalStorage.clear();
    navigate("/login");
    setShowSidebar(false);
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      if (user?.user?.isPasswordReset) {
        navigate("/change-password");
      }
    }
  }, [user]);

  useEffect(() => {
    if (secureLocalStorage.getItem("parlyBet")?.length > 0) {
      setParlyBet(secureLocalStorage.getItem("parlyBet"));
    }
  }, []);

  // Socket
  const [checkRefreshScore, setCheckRefreshScore] = useState(true);
  const [socketObjScore, setSocketObjScore] = useState(null);
  const [score, setScore] = useState([]);
  const getScore = (message) => {
    setScore(message);
  };
  const [cricketScore, setCricketScore] = useState([]);
  const scoreCricketAll = (e) => {
    setCricketScore(e);
  };

  const getSocket = () => {
    if (checkRefreshScore) {
      if (!isEmpty(socketObjScore)) {
        socketObjScore.disconnect();
      }
      setSocketObjScore(false);
      let newSocket = "";
      let randomId = user?.user?._id ? user?.user?._id : 112233;
      if (!isEmpty(user)) {
        newSocket = io(
          `${process.env.REACT_APP_API_SCORE_URL}?token=${randomId}&userType=front`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      } else {
        newSocket = io(
          `${process.env.REACT_APP_API_SCORE_URL}?user_id=${randomId}`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      }
      setSocketObjScore(newSocket);
      newSocket.on("scoreAll", getScore);
      newSocket.on("scoreCricketAll", scoreCricketAll);
      newSocket.on("disconnect", function () {
        setCheckRefreshScore(true);
      });
      return () => newSocket.close();
    }
  };

  useEffect(() => {
    if (
      checkRefreshScore &&
      ["sports", "full-market"].includes(location?.pathname?.split("/")[1])
    ) {
      getSocket();
    }
  }, [checkRefreshScore, location?.pathname]);

  document.addEventListener("visibilitychange", function () {
    if (["sports", "full-market"].includes(location?.pathname?.split("/")[1])) {
      if (!document.hidden && !checkRefreshScore) {
        setCheckRefreshScore(true);
        if (!isEmpty(socketObjScore)) {
          socketObjScore.disconnect();
        }
      } else {
        setCheckRefreshScore(false);
      }
    }
  });

  useEffect(() => {
    if (
      !["sports", "full-market"].includes(location?.pathname?.split("/")[1])
    ) {
      if (!isEmpty(socketObjScore)) {
        socketObjScore.disconnect();
      }
    } else {
      if (!socketObjScore?.connected) {
        setCheckRefreshScore(true);
      }
    }
  }, [location?.pathname]);

  const messageList = async () => {
    const { status, data: response_users } = await apiGet(apiPath.messageList);
    if (status === 200) {
      if (response_users.success) {
        setMessageList(response_users.results);
      }
    }
  };

  const [currentBetCount, setCurrentBetCount] = useState(0);
  const getCurrentBetCount = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.currentBetsCount
    );
    if (status === 200) {
      if (response_users.success) {
        setCurrentBetCount(response_users.results?.total);
      }
    }
  };

  useEffect(() => {
    if (showSidebar) {
      setShowSidebar(false);
    }
    if (location?.pathname?.split("/")[1] !== "full-market") {
      secureLocalStorage.removeItem("betFair");
      secureLocalStorage.removeItem("bookmaker");
      secureLocalStorage.removeItem("fancy");
      secureLocalStorage.removeItem("tie");
      secureLocalStorage.removeItem("premiumFancy");
      setShowWidget(true);
      setActiveWidget("");
    }
    if (location?.pathname?.split("/")[1] !== "profit-and-loss") {
      setShowDate({
        status: false,
        type: "",
      });
      setPlDate([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);
    }
  }, [location]);

  let getProfileData = async () => {
    const data = await apiGet(apiPath.userProfile);
    if (data?.status == 200) {
      if (data?.data?.success) {
        setProfileData(data?.data?.results);
        setDefaultStakeLimit(data?.data?.results?.defaultStakeLimit);
        setMatchLimit(data?.data?.results?.matchLimit);
        setBlockLimit(data?.data?.results?.blockSportMarket);
        setUserCoins({
          exp: data?.data?.results?.exposure,
          balance: data?.data?.results?.totalCoins,
        });
      }
    }
  };
  const [resetPassword, setResetPassword] = useState({
    status: true,
    detail: {},
  });
  let loginUser = async (body) => {
    let hostname = window.location.hostname;
    hostname = hostname.replace(/^www\./, "");
    hostname = hostname.replace(/^ag\./, "");
    body.website = hostname || "SABAEXCH";
    const { status, data } = await apiPost(
      apiPath.loginUser,
      pick(body, ["username", "password", "uniqueId", "website"])
    );
    if (status === 200) {
      if (data.success) {
        setInvalidCount(0);
        localStorage.removeItem("invalidCount");
        localStorage.removeItem("invalidCountTime");
        setLoginError("");
        localStorage.setItem("token", data.results.token);
        localStorage.setItem("refresh_token", data.results.refresh_token);
        window.location.reload();
        setUser(jwt_decode(data.results.token));
        if (data?.results?.isPasswordReset) {
          navigate("/change-password");
        } else {
          navigate("/");
        }
      } else {
        // console.log(data,'data')
        if (data?.message == "Password provided was in correct") {
          setCall(true);
        } else {
          // toast.error(data?.message);
        }
        setLoginError(data.message);
      }
    }
  };

  const amounutRefreshApi = async () => {
    const { status, data } = await apiGet(apiPath.refreshAmount);
    if (status === 200) {
      if (data.success) {
        setUserCoins({
          exp: data?.results?.exposure,
          balance: data?.results?.totalCoins,
        });
      }
    }
  };

  useEffect(() => {
    let data = localStorage.getItem("token")
      ? jwt_decode(localStorage.getItem("token"))
      : "";
    setUser(data);
  }, []);

  useEffect(() => {
    if (!isEmpty(user) && checkRefresh) {
      amounutRefresh();
    }
  }, [checkRefresh, user]);

  useEffect(() => {
    if (!isEmpty(user)) {
      let interval = setInterval(() => {
        getProfileData();
      }, 60000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [user]);
  useEffect(() => {
    if (!isEmpty(user)) {
      let interval = setInterval(() => {
        amounutRefreshApi();
      }, 10000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [user]);

  useEffect(() => {
    if (!isEmpty(user)) {
      getProfileData();
      messageList();
    }
  }, [user]);

  const smsList = async () => {
    const { status, data: response_users } = await apiGet(apiPath.smsList);
    if (status === 200) {
      if (response_users.success) {
        setMessageList(response_users.results);
      }
    }
  };
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      smsList();
    }
  }, [!localStorage.getItem("token")]);

  useEffect(() => {
    if (
      location?.pathname.split("/").includes("bkash") &&
      !isEmpty(location?.pathname?.split("/")[2])
    ) {
      localStorage.setItem("token", location?.pathname?.split("/")[2]);
      navigate("/");
      window.location.reload();
    }
    setCasinoUrl("");
    setCasinoUrlHome("");
  }, [location?.pathname]);

  const amounutRefresh = () => {
    setRefreshAmountLoader(true);
    getCurrentBetCount();

    setCheckRefresh(false);
    if (!isEmpty(socketObj)) {
      socketObj.disconnect();
    }
    const newSocket = io(
      `${process.env.REACT_APP_API_BASE_URL_OLD}?token=${localStorage.getItem(
        "token"
      )}&userType=front`,
      {
        transports: ["websocket"],
      }
    );
    const coinListener = (message) => {
      // console.log(message?.results,'message?.results?.exposure')
      setUserCoins({
        exp: message?.results?.exposure,
        balance: message?.results?.totalCoins,
      });
      setRefreshAmountLoader(false);
    };
    const forceLogout = (message) => {
      const uniqueId = localStorage.getItem("uniqueId");
      if (uniqueId !== message.results.uniqueId) {
        logoutUser();
      }
    };
    setSocketObj(newSocket);
    newSocket.emit("getCoins", { user_id: user ? user.user._id : "" });
    newSocket.on("listenGetCoin", coinListener);
    newSocket.on("listenForceLogout", forceLogout);
    newSocket.on("disconnect", function () {
      setRefreshAmountLoader(true);

      setCheckRefresh(true);
    });
    return () => newSocket.disconnect();
  };

  document.addEventListener("visibilitychange", function () {
    if (!document.hidden && user && !checkRefresh) {
      setCheckRefresh(true);
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
    } else {
      setCheckRefresh(false);
    }
  });

  useEffect(() => {
    setCasinoUrl("");
    setCasinoUrlHome("");
  }, []);

  const [casinoUrlHome, setCasinoUrlHome] = useState("");
  const launchEGTCasino = async (request) => {
    if (profileData?.betLock) {
      setMessage({
        ...message,
        status: true,
        message: "Current bet is locked please try again after sometime.",
        errorType: "Error",
      });
    } else {
      if (request?.platForm || request?.gameType || request?.casinoType) {
        const { status, data: response_users1 } = await apiPost(
          apiPath.easytogoCasinoAmountAdd,
          {
            prod: request?.platForm,
            type: request?.gameType,
          }
        );
        if (status === 200) {
          if (response_users1.success) {
            const { status, data: response_users } = await apiPost(
              apiPath.doLoginAndLaunchEasyToCasino,
              {
                prod: request?.platForm,
                type: request?.gameType,
                gameCode: request?.casinoType,
                gameid: request?.gameid || false,
              }
            );
            if (status === 200 && response_users.status) {
              if (response_users.data.err === 1) {
                localStorage.setItem("launchEGTCasino", request?.platForm);
                // window.location.href = response_users.data.url;
                setCasinoUrlHome(response_users.data.url);
              } else {
                setMessage({
                  ...message,
                  status: true,
                  errorType: "P2P",
                  message: response_users?.data?.desc,
                });
              }
            } else {
              setMessage({
                ...message,
                status: true,
                errorType: "P2P",
                message: response_users?.message,
              });
            }
          } else {
            setMessage({
              ...message,
              status: true,
              errorType: "P2P",
              message: response_users1?.message,
            });
          }
        }
      }
    }
    document.getElementById("loading").style.display = "none";
  };

  const launchCasino = async (request) => {
    if (profileData?.betLock) {
      setMessage({
        ...message,
        status: true,
        message: "Current bet is locked please try again after sometime.",
        errorType: "Error",
      });
    } else {
      if (request?.platForm || request?.gameType || request?.casinoType) {
        const { status, data: response_users1 } = await apiPost(
          apiPath.casinoAmountAdd,
          { amount: 0, platForm: request?.platForm }
        );
        if (status === 200) {
          if (response_users1.success) {
            const { status, data: response_users } = await apiPost(
              request?.platForm && request?.gameType && request?.casinoType
                ? apiPath.doLoginAndLaunchGame
                : apiPath.casinoWalletLogin,
              request?.platForm && request?.gameType && request?.casinoType
                ? request?.table
                  ? {
                      userId: response_users1?.results?.aeCasinoUserId,
                      platForm:
                        request?.platForm == "1" ? "" : request?.platForm,
                      gameType: request?.gameType,
                      gameCode: request?.casinoType,
                      gameTableId: request?.table,
                      isLaunchGameTable: true,
                    }
                  : {
                      userId: response_users1?.results?.aeCasinoUserId,
                      platForm:
                        request?.platForm == "1" ? "" : request?.platForm,
                      gameType: request?.gameType,
                      gameCode: request?.casinoType,
                    }
                : {
                    userId: response_users1?.results?.aeCasinoUserId,
                    gameType: request?.gameType,
                    platForm: request?.platForm == "1" ? "" : request?.platForm,
                  }
            );
            if (status === 200) {
              if (response_users.status) {
                if (response_users.data.status === "0000") {
                  if (request?.check) {
                    setCasinoUrl(response_users.data.url);
                  } else {
                    // window.location.href = response_users.data.url;
                    setCasinoUrlHome(response_users.data.url);
                  }
                } else {
                  setMessage({
                    ...message,
                    status: true,
                    errorType: "P2P",
                    message: response_users?.data?.desc,
                  });
                }
              }
            } else {
              setMessage({
                ...message,
                status: true,
                errorType: "P2P",
                message: response_users?.message,
              });
            }
          } else {
            setMessage({
              ...message,
              status: true,
              errorType: "P2P",
              message: response_users1?.message,
            });
          }
        } else {
          setMessage({
            ...message,
            status: true,
            errorType: "P2P",
            message: response_users1?.message,
          });
        }
      }
    }
    document.getElementById("loading").style.display = "none";
  };

  let contextData = {
    user: user,
    loginUser: loginUser,
    logoutUser: logoutUser,
    showSidebar,
    setShowSidebar,
    handleShowSidebar,
    setGameTab,
    gameTab,
    keyTime,
    setKeyTime,
    loginError,
    profileData,
    setShowPosition,
    showPosition,
    userCoins,
    setUserCoins,
    amounutRefresh,
    scrollHeight,
    setScrollHeight,
    showDate,
    setShowDate,
    plDate,
    setPlDate,
    plDateType,
    setPlDateType,
    getProfileData,
    refreshAmountLoader,
    setSearchTab,
    searchTab,
    showWidget,
    setShowWidget,
    setActiveWidget,
    activeWidget,
    messagelist: messagelist,
    announcement,
    setAnnouncement,
    launchEGTCasino,
    launchCasino,
    active,
    setActive,
    setFooterLink,
    footerLink,
    setMessage,
    message,
    setParly: updateParly,
    parly,
    parlyBet,
    setParlyBet,
    showParlyBet,
    setShowParlyBet,
    getCurrentBetCount,
    currentBetCount,
    casinoUrl,
    setCasinoUrl,
    loaded,
    setLoaded,
    scoreDetail,
    setScoreDetail,
    refreshAmount: amounutRefreshApi,
    casinoUrlHome,
    setCasinoUrlHome,
    sokcetId,
    setSocketId,
    resetPassword,
    setResetPassword,
    scoreAll: [...score, ...cricketScore],
    matchLimit,
    blockLimit,defaultStakeLimit
  };
  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};
