import React from "react";
import LayoutComponent from "../components/shared/Layout";
import { Link, useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import { BsFillTelephoneForwardFill } from "react-icons/bs";

const UplineWhatsapp = () => {
  const navigate = useNavigate();
  return (
    <LayoutComponent visibilityType={true}>
      <div className="balance-label position-relative">
        <button className="bg-transparent border-0 text-white position-absolute start-0 pt-0">
          <FaAngleLeft className="fs-3" onClick={() => navigate("/")} />
        </button>
        Upline Whatsapp Number
      </div>

      <div className="p-sm-3 p-2 pt-sm-4 pt-3 inner-sidebar-content">
        <h6 className="fs-6 fw-bold mb-3">WhatsApp Number</h6>
        <div className="bg-white whatsapp-area">
          <Link to="https://wa.me/15797940196">
            {" "}
            <div className="d-flex align-items-center fs-6">
              {" "}
              <img
                src="../assets/images/casino-icon/whatsapp.png"
                alt=""
                className="me-1"
              />{" "}
            +15797940196
            </div>{" "}
            <span className="bg-yellow">
              <BsFillTelephoneForwardFill />
            </span>
          </Link>
        </div>
      </div>
    </LayoutComponent>
  );
};

export default UplineWhatsapp;
