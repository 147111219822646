import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import {
  FaUserAlt,
  FaLock,
  FaShieldAlt,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";
import { IoChevronBackCircle } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import LayoutComponent from "../components/shared/Layout";
import AuthContext from "../context/AuthContext";
import { useForm } from "react-hook-form";
import { isEmpty } from "lodash";
import { IoIosArrowBack } from "react-icons/io";
const Login = () => {
  let { loginUser, user, loginError } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(true);
  const [remember, setRemeber] = useState(false);
  const [getValidateCode, setValidateCode] = useState("");
  const [clickClass, setClickClass] = useState(false);

  const navigate = useNavigate();
  const handleShow = () => {
    setShowPassword(!showPassword);
  };
  const canvasRef = useRef(null);
  const changeCode = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.font = "bold 120px sans-serif";
    const code = Math.floor(1000 + Math.random() * 9000);
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillText(code, 0, 130);
    setValidateCode(code);
  };
  useEffect(() => {
    setInterval(function(){setClickClass(false);},1000);
    
  }, [clickClass]);

  useEffect(() => {
    changeCode();
  }, []);

  useEffect(() => {
    if (!isEmpty(user)) {
      navigate("/");
    }
  }, [user]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    unregister,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
      uniqueId: Math.random() * 10000,
    },
  });

  useEffect(() => {
    if (!isEmpty(errors)) {
      changeCode();
    }
  }, [errors]);

  const [modal, setModal] = useState(false);
  return (
    <div>
      <LayoutComponent>
        <div className="d-flex align-items-center justify-content-start">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              background: "white",
              borderRadius: "50%",
              marginLeft: "8px",
              marginTop: "8px",
            }}
            onClick={() => navigate("/")}
          >
            <IoIosArrowBack size={30} className="fs-1 p-1 cursor-pointer" />
          </div>
        </div>

        <div className="login-log-sec w-100">
          <div className="login-logo">
            <img src="../assets/images/velki-logo.png" alt="" />
          </div>
          <div className="px-2" style={{ position: "relative" }} 
          
          onClick={() =>
               window.open("https://allaglist.com", "_self")
                // window.open("https://wa.me/+919871746317", "_self")
               
              }>
            {/* <span
              className="login-number"
              onClick={() =>
                window.open("https://velkionlineagentlist.live/", "_self")
              }
            >
              +601112074580
            </span> */}
            <span
              onClick={() =>
                window.open("https://velkionlineagentlist.live/", "_self")
              }
            >
              {" "}
              <img
                src="../assets/images/velki-login-signup-banner-new1.webp"
                alt=""
              />{" "}
            </span>
          </div>
          <Form onSubmit={handleSubmit(loginUser)} className="bg-white p-4">
            <label className="mb-3 d-block text-center fs-2">LOGIN</label>
            <Form.Group className="mb-3">
              <FloatingLabel controlId="floatingInput" label="Username">
                <Form.Control
                  {...register("username", {
                    required: "Please enter Username",
                  })}
                  className={`login-input ${
                    !isEmpty(watch("username")) ? "login-input-border" : ""
                  }`}
                  id="input-user"
                  autocomplete="off"
                  type="text"
                  placeholder="Username"
                  style={
                    errors.username
                      ? {
                          borderColor: "red",
                        }
                      : {}
                  }
                />
                <div
                  style={{ left: "10px" }}
                  className="position-absolute top-0 flex items-center h-40px ml-2 space-x-3px start-icon"
                >
                  {" "}
                  <span
                    className={`${
                      !isEmpty(watch("username")) ? "icon-content-selected" : ""
                    } position-absolute  translate-middle-y top-50 icon-content text-18 text-black-400 top-3 left-3 z-10 icon-user`}
                  >
                    <i class="icon-flag-bangladesh path1 z-10 text-18"></i>
                  </span>
                </div>
              </FloatingLabel>
              {errors.username && errors?.username?.message && (
                <label className="text-danger">{errors.username.message}</label>
              )}
            </Form.Group>
            <Form.Group className="mb-4">
              <FloatingLabel controlId="floatingPassword" label="Password">
                <Form.Control
                  type={!showPassword ? "text" : "password"}
                  placeholder="Password"
                  className={`login-input ${
                    !isEmpty(watch("password")) ? "login-input-border" : ""
                  }`}
                  maxLength={15}
                  {...register("password", {
                    required: "Please enter password",
                  })}
                  style={
                    errors.password
                      ? {
                          borderColor: "red",
                        }
                      : {}
                  }
                />
                <div
                  style={{ left: "10px" }}
                  className="position-absolute top-0 flex items-center h-40px ml-2 space-x-3px start-icon"
                >
                  {" "}
                  <span
                    className={`${
                      !isEmpty(watch("password")) ? "icon-content-selected" : ""
                    } position-absolute  translate-middle-y top-50 icon-content text-18 text-black-400 top-3 left-3 z-10 icon-lock`}
                  >
                    <i class="icon-flag-bangladesh path1 z-10 text-18"></i>
                  </span>
                </div>
                <Button
                  className="position-absolute translate-middle-y top-50 end-0 me-2 bg-transparent border-0 text-dark p-0 "
                  onClick={() => handleShow()}
                >
                  {!showPassword ? (
                    <FaEye className="opacity-100 fs-5" size="17" />
                  ) : (
                    <FaEyeSlash className="opacity-100 fs-5" size="17" />
                  )}
                </Button>
              </FloatingLabel>
              {errors.password && errors?.password?.message && (
                <label className="text-danger">{errors.password.message}</label>
              )}
            </Form.Group>
            <Form.Group className="mb-4">
              <FloatingLabel controlId="floatingValid" label="ValidationCode">
                <Form.Control
                  type="number"
                  keyboardType="numeric"
                  autocomplete="off"
                  className={`login-input ${
                    !isEmpty(watch("validateCode")) ? "login-input-border" : ""
                  }`}
                  maxLength="4"
                  {...register("validateCode", {
                    required: "Please enter validate code",
                    validate: {
                      validate: (value) =>
                        parseInt(value) === parseInt(getValidateCode) ||
                        "Invalid validate code",
                    },
                  })}
                  style={
                    errors.validateCode
                      ? {
                          borderColor: "red",
                        }
                      : {}
                  }
                  onChange={(e) => {
                    if (e.target.value.length == 4) {
                      e.target.blur();
                      unregister("validateCode", { keepValue: true });
                    }
                  }}
                  placeholder="ValidationCode"
                />
                <div
                  style={{ left: "10px" }}
                  className="position-absolute top-0 flex items-center h-40px ml-2 space-x-3px start-icon"
                >
                  {" "}
                  <span
                    className={`${
                      !isEmpty(watch("validateCode"))
                        ? "icon-content-selected"
                        : ""
                    } position-absolute  translate-middle-y top-50 icon-content text-18 text-black-400 top-3 left-3 z-10 icon-shield`}
                  >
                    <i class="icon-flag-bangladesh path1 z-10 text-18"></i>
                  </span>
                </div>
                <canvas
                  ref={canvasRef}
                  onClick={changeCode}
                  className="position-absolute translate-middle-y top-50 canvas-img"
                  id="authenticateImage"
                />
              </FloatingLabel>
              {errors.validateCode && errors?.validateCode?.message && (
                <label className="text-danger">
                  {errors.validateCode.message}
                </label>
              )}
            </Form.Group>

            <div>
              <label
                for="remember"
                className="d-flex justify-content-start align-items-center select-none"
              >
                <span
                  onClick={() => setRemeber(!remember)}
                  style={{
                    width: "1rem",
                    height: "1rem",
                    background: remember ? "#ffc800" : "#fff4c1",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid black",
                    borderRadius: ".25rem",
                    paddingTop: "2px",
                  }}
                >
                  {remember && (
                    <span className="icon-check text-checkbox-icon text-8 font-bold"></span>
                  )}
                </span>
                <div style={{ paddingLeft: "5px" }}>Remember me </div>
              </label>
            </div>
            {loginError && <label className="text-danger">{loginError}</label>}

            <div className="text-center mt-3">
              <Button type="submit" className={clickClass?"noHover theme-btn ms-auto px-5 login-focus":"noHover theme-btn ms-auto px-5" }  onClick={() => setClickClass(true)} >
                Login
              </Button>
            </div>
          </Form>
        </div>
      </LayoutComponent>

    </div>
  );
};

export default Login;
